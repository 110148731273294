/*******************************
         Site Overrides
*******************************/

/*----------------------------
    Custom Semantic Classes
-----------------------------*/

/* General Colors */
body {
    background-color: @white!important;
}
.color {
    &.primary {
        color: @primaryColor!important;
        &.background {
            color: @white!important;
            background-color: @primaryColor!important;
        }
    }
    &.secondary {
        color: @secondaryColor!important;
        &.background {
            color: @white!important;
            background-color: @secondaryColor!important;
        }
    }
}

/* General Text transformers */
.text {
    &.bold {
        font-weight: bold;
    }
}

.ui.form .fields:not(.grouped):not(.inline) .field:not(:only-child) .ui.checkbox {
  margin-top: 0 !important;
}
