/*******************************
         Site Overrides
*******************************/

.ui.top.menu .active.item {
    background: @topMenuActiveItemBackground;
    color: @topMenuActiveItemColor;
}

.ui.top.menu .dropdown.item:hover,
.ui.top.menu .link.item:hover,
.ui.top.menu a.item:hover {
    background: @topMenuHoverItemBackground;
    color: @topMenuHoverItemColor;
}

/* Active Hover */
.ui.top.menu .active.item:hover {
    background: @topMenuActiveHoverItemBackground;
    color: @topMenuActiveHoverItemColor;
}